<template>
   <v-expand-transition>
      <v-form ref="form">
         <div v-if="typeof option.id === 'string' || option.id >= 0">
            <v-card-text class="pt-9">
               <v-row>
                  <v-col v-if="$store.state.user.code === 'SPA'" cols="12" md="3">
                     <v-select v-model="form.zone" :items="zones" label="Management Zone" placeholder="Zona Manajemen" outlined hide-details required :rules="[v => !!v]"
                        item-text="name" item-value="zone"
                        :return-object="false" :menu-props="{ bottom: true, offsetY: true }"
                        />
                  </v-col>
               </v-row>
               <v-row>
                  <v-col cols="12" md="4">
                     <v-text-field v-model="form.name" label="Application Code" placeholder="Kode Aplikasi" outlined hide-details required :rules="[v => !!v]">
                        <template slot="append-outer">
                           <v-btn tile large height="56" width="56" icon @click="$refs.optionLogo.click()">
                              <v-avatar v-if="form.Logo" tile size="56">
                                 <img :src="setUrlFromImage(form.Logo)" alt="Logo" style="object-fit: cover;" @error="() => { form.Logo = '' }">
                              </v-avatar>
                              <v-icon v-else tile size="68">
                                 mdi-image
                              </v-icon>
                              <input ref="optionLogo" type="file" accept="image/*" alt="optionLogo" style="display:none;" @change="attachmentPreview">
                           </v-btn>
                        </template>
                     </v-text-field>
                  </v-col>
                  <v-col cols="12" md="5">
                     <v-text-field v-model="form.title" label="Application Title" placeholder="Judul Aplikasi" outlined hide-details />
                  </v-col>
                  <v-col cols="12" md="3">
                     <v-text-field v-model="form.copyright" label="Copyright" placeholder="Hak Cipta" outlined hide-details />
                  </v-col>
                  <v-col cols="12" md="6">
                     <v-text-field v-model="form.address" label="Address" placeholder="Alamat Baris 1" outlined hide-details />
                  </v-col>
                  <v-col cols="12" md="6">
                     <v-text-field v-model="form.address2" label="Address 2" placeholder="Alamat Baris 2" outlined hide-details />
                  </v-col>
                  <v-col cols="12" md="3">
                     <v-text-field v-model="form.phone" label="Phone" placeholder="Kontak 1" outlined hide-details />
                  </v-col>
                  <v-col cols="12" md="3">
                     <v-text-field v-model="form.phone2" label="Phone 2" placeholder="Kontak 2" outlined hide-details />
                  </v-col>
                  <v-col cols="12" md="3">
                     <v-text-field v-model="form.email" label="Email" placeholder="Surat Elektronik 1" outlined hide-details required :rules="[v => !v || /^\w+([-\+\.]?\w+)*@\w+([-\.]?\w+)*(\.\w{2,4})+$/.test(v)]" />
                  </v-col>
                  <v-col cols="12" md="3">
                     <v-text-field v-model="form.email2" label="Email 2" placeholder="Surat Elektronik 2" outlined hide-details required :rules="[v => !v || /^\w+([-\+\.]?\w+)*@\w+([-\.]?\w+)*(\.\w{2,4})+$/.test(v)]" />
                  </v-col>
               </v-row>
            </v-card-text>
            <v-divider />
            <v-card-text class="pb-6">
               <v-row class="mt-0">
                  <v-col cols="12" class="py-0 d-flex">
                     <v-btn v-if="$hasRole(option.kind.toLowerCase(), 'CU')" x-large color="accent" depressed class="mr-4" :loading="$store.state.process === 'updateOption'" @click="updateRecord">
                        Update
                     </v-btn>
                     <v-btn v-if="$store.state.user.code === 'SPA'" x-large color="accent" depressed outlined class="mr-4" @click="option.id = -1">
                        {{ $store.state.process === 'updateOption' ? 'Close' : 'Cancel' }}
                     </v-btn>
                     <v-spacer />
                  </v-col>
               </v-row>
            </v-card-text>
            <v-divider />
         </div>
      </v-form>
   </v-expand-transition>
</template>

<script>
import { imageFromUrl, blobImageToBase64 } from '@/utils/nirtara'
const empty = { Logo: '', kind: 'Global', name: 'NVM-50-WEB', title: '', copyright: '', address: '', address2: '', phone: '', phone2: '', email: '', email2: '' }
export default {
   name: 'NGlobalForm',

   props: {
      option: { type: Object },
      zones: { type: Array },
   },
   data: () => ({
      isUsed: false,
      form: Object.assign({}, empty),
   }),

   computed: {
      setUrlFromImage () {
         return image => imageFromUrl(image)
      },
   },
   watch: {
      'option.id' (id) {
         if (id < 0) return
         id === 0 && this.$refs.form.reset()
         this.form = Object.assign({}, empty, typeof id === 'string' ? this.option : { id: 0 })
      },
   },
   mounted () {
   },
   methods: {
      attachmentPreview (event) {
         blobImageToBase64(this.$refs[event.target.alt].files[0])
         .then(base64 => {
            switch (event.target.alt) {
               default: console.log(`${event.target.alt}: ${base64.substr(0, 32)}....`)
                  break
               case 'optionLogo': this.form.Logo = base64
                  break
            }
         })
      },
      updateRecord () {
         if (!this.$refs.form.validate()) return
         this.$emit('update', this.form)
      },
   },
}
</script>

<style lang="scss" scoped>
.col-12 {
   padding: 0 12px 20px 12px;
}
::v-deep .v-text-field--outlined .v-input__append-outer {
   margin: auto 0 auto 16px;
}
.v-btn:not(.v-btn--round).v-size--x-large {
   min-width: 115px;
}
</style>
